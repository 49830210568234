import { useMemo } from 'react'

import { cartUtils } from './use-cart-utils-checkout.utils'
import { TUseCartUtilsCheckout } from './use-cart-utils-checkout.types'

// TODO: come back to this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
export const useCartUtilsCheckout: TUseCartUtilsCheckout = ({
  cart,
  deliveryCharge
}) => {
  return useMemo(() => cartUtils(cart, deliveryCharge), [cart, deliveryCharge])
}

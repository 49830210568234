import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { format, isWithinInterval, isValid } from 'date-fns'
import { useModal } from '@ebay/nice-modal-react'
import { useFormContext } from 'react-hook-form'

import { ChevronDownIcon } from '@chakra-ui/icons'
import { Collapse, useDisclosure } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { VStack, HStack } from '@tofu/shared/ui/atoms/stack'
import { formatPrice } from '@tofu/shared/utils/format-currency'
import { useCartUtilsCheckout } from '@tofu/checkout/hooks/use-cart-utils-checkout'
import { useLayoutUtilsCheckout } from '@tofu/checkout/hooks/use-layout-utils-checkout'
import { TemplateCheckoutLoading } from '@tofu/checkout/ui/templates/template-loading'

import {
  LayoutCheckout,
  LayoutCheckoutHeader,
  LayoutCheckoutContainer,
  LayoutCheckoutContentMain,
  LayoutCheckoutContentAside
} from '@tofu/checkout/ui/organisms/layout-checkout'
import { useRudderStackAnalytics } from '@tofu/checkout/hooks/use-rudderstack-analytics-checkout'
import { sendEvent } from '@tofu/checkout/utils/utils-tracking-checkout'

import { TemplateCheckoutForm } from './template-checkout-form'
import { TemplateCheckoutDishes } from './template-checkout-dishes'
import { TemplateCheckoutOrderSummary } from './template-checkout-order-summary'
import { TemplateCheckoutModalDeliveryDate } from './template-checkout-modal-delivery-date'
import { TemplateCheckoutModalDeliveryDateError } from './template-checkout-modal-delivery-date-error'

import {
  useTemplateCheckoutStipe,
  useTemplateCheckoutDiscounts,
  useTemplateCheckoutDeliveryDates
} from './template-checkout.hooks'

import { TTemplateCheckout } from './template-checkout.types'

export const TemplateCheckout: TTemplateCheckout = ({
  cart,
  cartToken,
  deliveryCharge,
  minimumChargeValue,
  dateDeliveryParam
}) => {
  const { getValues } = useFormContext()

  const { rudderstackAnalytics } = useRudderStackAnalytics()
  const [dateDelivery, setDateDelivery] = useState<Date>()
  const [checkedEmailAddress, setCheckedEmailAddress] = useState('')

  const modalDeliveryPicker = useModal(TemplateCheckoutModalDeliveryDate)
  const modalDeliveryDateError = useModal(
    TemplateCheckoutModalDeliveryDateError
  )
  const isCartTotalPriceBelowMinChargeValue =
    cart.total_price / 100 < minimumChargeValue

  const {
    cartDiscountTotal,
    cartDeliveryItemPrice,
    totalSavings,
    cartDiscountCode,
    cartPriceTotal,
    cartPriceSubtotal,
    isCartSubscription,
    cartDeliveryFrequency,
    cartItemsWithoutDeliveryCharge
  } = useCartUtilsCheckout({ cart, deliveryCharge }) || {}

  const { dateMin, dateMax, datesExcluded, dateFirst } =
    useTemplateCheckoutDeliveryDates({
      items: cartItemsWithoutDeliveryCharge
    })

  const {
    discountField,
    setDiscountField,
    // checkDiscount,
    handleOnApplyDiscount,
    handleOnRemoveDiscount,
    isLoadingApplyDiscount
  } = useTemplateCheckoutDiscounts({ cart, cartToken })

  const { formErrors, isStripeReady, onSubmitCheckoutForm } =
    useTemplateCheckoutStipe({
      cartToken,
      dateDelivery
    })

  // move to utils
  const isDateWithinRange = (date: Date | undefined): boolean => {
    if (!date || !dateMin || !dateMax) return false
    return isWithinInterval(date, { start: dateMin, end: dateMax })
  }

  const handleOnSubmitCheckoutForm = () => {
    if (!isDateWithinRange(dateDelivery)) {
      const date = format(dateFirst, 'dd/MM/yyyy')
      modalDeliveryDateError.show({ date })
      setDateDelivery(dateFirst)
    } else {
      onSubmitCheckoutForm()
    }
  }

  useEffect(() => {
    if (dateDeliveryParam) {
      isValid(dateDeliveryParam) && setDateDelivery(dateDeliveryParam)

      const url = new URL(window?.location?.href)
      url?.searchParams?.delete('deliveryDate')
      window?.history?.replaceState({}, '', url.toString())
    } else {
      isValid(dateFirst) && setDateDelivery(dateFirst)
    }
  }, [dateDeliveryParam, dateFirst])

  const { isOpenOrderSummary, onToggleOrderSummary } = useLayoutUtilsCheckout()

  // TODO: display an error message?
  const isSubmitButtonDisabled =
    !isStripeReady || cart?.status !== 'PURCHASABLE'

  // this is the order summary nested inside TemplateCheckoutForm (mobile)
  const {
    isOpen: isOpenOrderSummaryMobile,
    onToggle: onToggleOrderSummaryMobile
  } = useDisclosure({
    defaultIsOpen: false
  })

  const dateDeliveryFormatDistanceToNow = useMemo(
    () =>
      dateDelivery && isValid(dateDelivery)
        ? format(dateDelivery, 'EEEE do MMMM')
        : '',

    [dateDelivery]
  )

  const onChangeDate = (date: Date) => {
    setDateDelivery(date)
    modalDeliveryPicker.hide()
  }

  const handleOnClickDeliveryDateChange = () => {
    modalDeliveryPicker.show({
      dateDelivery,
      dateMax: dateMax,
      dateMin: dateMin,
      onChangeDate: onChangeDate,
      datesExcluded: datesExcluded
    })
  }

  const handleOnBlurInputEmail = () => {
    const email = getValues('linkAuthentication')?.value

    sendEvent('anonymous-user.identified', {
      event_data: {
        email: email?.email
      }
    })

    sendEvent('checkout.started', {
      // eslint-disable-next-line camelcase
      event_data: {
        account: {
          email
        },
        cart: cart
      }
    })

    // ensure we don't check the previous email
    if (email && email !== checkedEmailAddress) {
      //  this is where we'd check the email address if they can use the discount
      // note for when we do this: it could be an object which happens when link authentication is used
      // checkDiscount(email)

      setCheckedEmailAddress(email)
      const anonymousId = rudderstackAnalytics?.getAnonymousId()
      anonymousId && rudderstackAnalytics?.identify(anonymousId, { email })
    }
  }

  const renderOrderSummary = () => (
    <TemplateCheckoutOrderSummary
      width='100%'
      totalSavings={totalSavings}
      discountField={discountField}
      cartPriceTotal={cartPriceTotal}
      deliveryCharge={deliveryCharge}
      cartDiscountCode={cartDiscountCode}
      setDiscountField={setDiscountField}
      cartDiscountTotal={cartDiscountTotal}
      cartPriceSubtotal={cartPriceSubtotal}
      onApplyDiscount={handleOnApplyDiscount}
      onRemoveDiscount={handleOnRemoveDiscount}
      cartDeliveryItemPrice={cartDeliveryItemPrice}
      isLoadingApplyDiscount={isLoadingApplyDiscount}
      isCartTotalPriceBelowMinChargeValue={isCartTotalPriceBelowMinChargeValue}
    />
  )

  const renderDishes = () => (
    <TemplateCheckoutDishes items={cartItemsWithoutDeliveryCharge} />
  )

  if (!isStripeReady) {
    return <TemplateCheckoutLoading />
  }

  return (
    <LayoutCheckout>
      <LayoutCheckoutHeader shouldShowBasketIcon={true} />
      <LayoutCheckoutContainer>
        <LayoutCheckoutContentMain>
          <TemplateCheckoutForm
            formErrors={formErrors}
            onBlurInputEmail={handleOnBlurInputEmail}
            dateDelivery={dateDeliveryFormatDistanceToNow}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            onSubmitCheckoutForm={handleOnSubmitCheckoutForm}
            onClickDeliveryDateChange={handleOnClickDeliveryDateChange}
          >
            <Box display={{ base: 'block', lg: 'none' }} width='100%'>
              <Flex
                direction='row'
                as={Button}
                variant='ghost'
                onClick={onToggleOrderSummaryMobile}
                justifyContent='space-between'
                alignItems='center'
                display={{ base: 'flex', lg: 'none' }}
                width='100%'
                p={0}
                sx={{
                  '&:hover, &:focus': {
                    cursor: 'pointer',
                    background: 'transparent'
                  }
                }}
              >
                <Box>
                  <Text as='h4' fontSize='xl' color='black' fontWeight='bold'>
                    Order summary
                  </Text>
                </Box>

                <Text
                  variant='link'
                  textDecoration='underline'
                  colorScheme='black'
                  fontSize='sm'
                  color='#1773b0'
                  as='span'
                >
                  Show
                  <ChevronDownIcon />
                </Text>
              </Flex>

              <Box width='100%'>
                <Collapse in={isOpenOrderSummaryMobile}>
                  <Box py={4}>{renderDishes()}</Box>
                </Collapse>
                {renderOrderSummary()}
              </Box>
            </Box>
          </TemplateCheckoutForm>
        </LayoutCheckoutContentMain>

        <LayoutCheckoutContentAside>
          <Flex
            direction='row'
            as={Button}
            variant='ghost'
            onClick={onToggleOrderSummary}
            justifyContent='space-between'
            display={{ base: 'flex', lg: 'none' }}
            width='100%'
            py={6}
            sx={{
              '&:hover, &:focus': {
                cursor: 'pointer',
                background: 'transparent'
              }
            }}
          >
            <Text
              variant='link'
              textDecoration='underline'
              colorScheme='black'
              fontSize='sm'
              as='span'
              color='#1773b0'
            >
              Show order summary
              <ChevronDownIcon />
            </Text>

            {cartPriceTotal && (
              <VStack spacing={0} alignItems='end'>
                {cartPriceSubtotal > cartPriceTotal && (
                  <Text
                    as='span'
                    color='gray.500'
                    fontSize='xs'
                    textDecoration='line-through'
                  >
                    {formatPrice(cartPriceSubtotal)}
                  </Text>
                )}
                <Text as='span' fontSize='lg'>
                  {formatPrice(cartPriceTotal)}
                </Text>
              </VStack>
            )}
          </Flex>

          <Collapse
            animateOpacity
            unmountOnExit
            in={isOpenOrderSummary && !undefined}
          >
            <Box px={{ base: 4, lg: 8 }} py={{ base: 2, lg: 8 }}>
              <HStack width='100%' mb={3} alignItems='baseline'>
                {isCartSubscription && (
                  <Text fontSize='sm' color='gray.500'>
                    Subscription every {cartDeliveryFrequency} weeks
                  </Text>
                )}
              </HStack>

              <VStack width='100%' mb={4}>
                <Box width='100%'>
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      {renderDishes()}
                    </motion.div>
                  </AnimatePresence>
                </Box>
                {renderOrderSummary()}
              </VStack>
            </Box>
          </Collapse>
        </LayoutCheckoutContentAside>
      </LayoutCheckoutContainer>
    </LayoutCheckout>
  )
}

import { ModalCloseButton as ChakraModalCloseButton } from '@chakra-ui/react'
import { CloseIcon } from '@tofu/shared/ui/atoms/icons'

export {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody
} from '@chakra-ui/react'

const ModalCloseButton = ChakraModalCloseButton

ModalCloseButton.defaultProps = {
  children: <CloseIcon />
}

export { ModalCloseButton }

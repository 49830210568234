import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useForm, FormProvider } from 'react-hook-form'
import { Heading, Link, useToast } from '@chakra-ui/react'

import { Text } from '@tofu/shared/ui/atoms/text'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { useQueryCart } from '@tofu/checkout/hooks/use-query-cart'
import { useQueryConstants } from '@tofu/shared/hooks/use-query-constants'
import { TemplateCheckout } from '@tofu/checkout/ui/templates/template-checkout'
import { TemplateCheckoutError } from '@tofu/checkout/ui/templates/template-error'
import { TemplateCheckoutLoading } from '@tofu/checkout/ui/templates/template-loading'
import { setUserData } from '@tofu/checkout/utils/utils-tracking-checkout'

import { TPageCheckout } from './page-checkout.types'
import { getFirstString, isValidDateFormat } from './page-checkout.utils'
import { usePageCheckoutTracking } from './page-checkout.hooks'

// Sending Stripe public key here. This is safe for us to do in client-side.
const stripePromise = loadStripe(process.env['STRIPE_PROMISE'] as string)

export const PageCheckout: TPageCheckout = () => {
  const methods = useForm()
  const toast = useToast()
  const { query } = useRouter()
  const [isClient, setIsClient] = useState(false)
  const [cartToken, setCartToken] = useState('')
  const { data: constants } = useQueryConstants()
  const { data: cart } = useQueryCart({
    cartToken
  })

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const isLoading = !cart?.total_price || !constants?.deliveryCharge

  useEffect(() => {
    if (isLoading) {
      timeoutRef.current = setTimeout(() => {
        toast({
          duration: null,
          isClosable: true,
          title: 'Still loading...',
          description:
            "Sorry, we're taking a little longer than usual to load your cart. Please bear with us. Or refresh the page."
        })
      }, 6000)
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const { deliveryDate } = query

  // tracking scripts
  usePageCheckoutTracking({ cart })

  const dateDeliveryString = deliveryDate && getFirstString(deliveryDate)

  const dateDeliveryParam =
    dateDeliveryString && isValidDateFormat(dateDeliveryString)
      ? new Date(dateDeliveryString)
      : undefined

  const token = query['token'] || Cookies.get('session_cart_token')

  useEffect(() => {
    if (!token) return

    setIsClient(true)
    setCartToken(token as string)

    // TODO: move this to the tracking hook or component on this page, not nested in the useEffect
    setUserData({
      // eslint-disable-next-line camelcase
      cart_token: token
    })
  }, [token])

  // prevent hydration error
  if (!isClient) {
    return <TemplateCheckoutLoading />
  }

  if (!cart) {
    return <TemplateCheckoutLoading />
  }

  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current)
  }

  if (cart?.status === 'COMPLETED') {
    return (
      <TemplateCheckoutError>
        <VStack>
          <Heading fontSize='lg'>
            Oops! It looks like you've already purchased the items in this cart.
          </Heading>
          <Text>
            Want to fill up a new order instead?{' '}
            <Link href='https://allplants.com' textDecoration='underline'>
              back to shop
            </Link>
          </Text>
        </VStack>
      </TemplateCheckoutError>
    )
  }

  if (cart?.status === 'EDITING') {
    return (
      <TemplateCheckoutError>
        <VStack>
          <Heading fontSize='lg'>
            Your cart is below the £{constants?.minimumOrderValue} minimum order
            value.
          </Heading>
          <Text>
            Please add more items in order to checkout. Back to{' '}
            <Link href='https://allplants.com' textDecoration='underline'>
              Shop
            </Link>
          </Text>
        </VStack>
      </TemplateCheckoutError>
    )
  }

  if (!cartToken || cart?.status === 'INITIAL') {
    return (
      <TemplateCheckoutError>
        <VStack>
          <Heading fontSize='lg'>Oops! Looks like your cart is empty.</Heading>
          <Text>
            Please add some tasty dishes first
            {` `}
            <Link href='https://allplants.com' textDecoration='underline'>
              allplants.com
            </Link>
          </Text>
        </VStack>
      </TemplateCheckoutError>
    )
  }

  if (
    cart?.status !== 'FULL' &&
    cart?.status !== 'CHECKOUT' &&
    cart?.status !== 'PURCHASABLE'
  ) {
    return (
      <TemplateCheckoutError>
        <VStack>
          <Heading fontSize='lg'>Oops! Looks like your cart is empty.</Heading>
          <Text>
            Please add some tasty dishes first
            {` `}
            <Link href='https://allplants.com' textDecoration='underline'>
              allplants.com
            </Link>
          </Text>
        </VStack>
      </TemplateCheckoutError>
    )
  }

  const setupFutureUsage =
    cart?.subscription_type === 'RECURRING' ? 'off_session' : null

  const options = {
    setupFutureUsage,
    currency: 'gbp',
    mode: 'payment',
    amount: cart?.total_price_to_charge,
    allowedShippingCountries: ['GB']
  } as StripeElementsOptions

  return (
    <Elements stripe={stripePromise} options={options}>
      <FormProvider {...methods}>
        <TemplateCheckout
          cart={cart}
          cartToken={cartToken}
          dateDeliveryParam={dateDeliveryParam}
          deliveryCharge={constants?.deliveryCharge ?? 0}
          minimumChargeValue={constants?.minimumChargeValue ?? 0}
        />
      </FormProvider>
    </Elements>
  )
}

import NiceModal, { useModal } from '@ebay/nice-modal-react'
import styled from 'styled-components'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Box } from '@tofu/shared/ui/atoms/box'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody
} from '@tofu/shared/ui/atoms/modal'
import { DeliveryCalendar } from '@tofu/shared/ui/molecules/delivery-calendar'
import { TTemplateCheckoutModalDeliveryDateProps } from './template-checkout-modal-delivery-date.types'

const CalendarStyles = styled(Box)`
  .react-datepicker {
    font-family: unset;
    font-size: 1rem;
    border: 0;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__navigation-icon:before {
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__navigation-icon--next:before {
    border-color: var(--text);
  }
  .react-datepicker__navigation-icon--previous:before {
    border-color: #999;
  }

  .react-datepicker__header {
    padding-top: 0;
    background-color: transparent;
  }

  .react-datepicker__header,
  .react-datepicker__time-container {
    border: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: inherit;
    font-weight: 700;
    color: var(--text);
  }

  .react-datepicker__month {
    background-color: var(--monthBackground);
  }

  .react-datepicker__day {
    color: var(--text);
    margin: 0;
    height: 2.4rem;
    line-height: 2.4rem;
    width: 2.4rem;
  }

  .react-datepicker__day-name {
    color: #999;
    font-size: 0.9rem;
    width: 2.4rem;
    margin: 0;
  }

  .react-datepicker__day--disabled {
    color: #999;
  }

  .react-datepicker__day-names {
    margin: 1rem 0 -1rem 0;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: #092a50;
    font-weight: normal;
    color: #fff;
  }

  .react-datepicker__day--today {
    font-weight: 400;
  }
`

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalDeliveryDate = NiceModal.create(
  ({
    dateMax,
    dateMin,
    onChangeDate,
    datesExcluded,
    dateDelivery
  }: TTemplateCheckoutModalDeliveryDateProps) => {
    const modal = useModal()

    return (
      <Modal
        size='xs'
        isCentered
        isOpen={modal.visible}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex width='100%' align='center' direction='column' py={4}>
              <CalendarStyles>
                <DeliveryCalendar
                  maxDate={dateMax}
                  minDate={dateMin}
                  handleChange={onChangeDate}
                  excludedDates={datesExcluded}
                  selectedDate={dateDelivery}
                />
              </CalendarStyles>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)

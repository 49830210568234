import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { CopyIcon, CheckIcon } from '@chakra-ui/icons'
import { useClipboard } from '@chakra-ui/react'
import { Text } from '@tofu/shared/ui/atoms/text'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@tofu/shared/ui/atoms/modal'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Button } from '@tofu/shared/ui/atoms/button'
import { TTemplateCheckoutModalDiscountErrorProps } from './template-checkout-modal-discount-error.types'

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalDiscountError = NiceModal.create(
  ({ description }: TTemplateCheckoutModalDiscountErrorProps) => {
    const modal = useModal()
    const { onCopy, hasCopied } = useClipboard('5OFF')

    const icon = hasCopied ? (
      <CheckIcon transform='translateX(-3px)' />
    ) : (
      <CopyIcon transform='translateX(-3px)' />
    )

    return (
      <Modal
        size='md'
        isCentered
        isOpen={modal.visible}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <VStack align='flex-start' spacing={6}>
              <Text>{description}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent='space-between'>
            <Text fontSize='sm'>
              Why not try{` `}
              <Button
                px={1}
                size='xs'
                color='black'
                onClick={onCopy}
                rightIcon={icon}
                textDecoration='underline'
              >
                5OFF
              </Button>{' '}
              for 5% off your order instead?
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
)

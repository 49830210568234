import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useErrorBoundary } from 'react-error-boundary'
import { TCart } from '@tofu/shared/types/cart'
import { getCart } from '@tofu/shared/data-access/cart'

import { TUseQueryCart } from './use-query-cart.types'

export const useQueryCart = ({ cartToken, options }: TUseQueryCart) => {
  const { showBoundary } = useErrorBoundary()

  return useQuery(['getCart', cartToken], () => getCart(cartToken), {
    enabled: !!cartToken,
    retry: 1,
    onError: (error) => {
      console.log('error', error)
      showBoundary(error)
    },
    ...options
  } as UseQueryOptions<TCart, Error, TCart, string[]>)
}

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'

// import { TLayoutCheckout } from './layout-checkout.types'

export const LayoutCheckoutContentMain = ({ children, ...propsFlex }) => (
  <Flex
    as='main'
    order={{ base: 1, lg: 0 }}
    width={{ base: '100%', lg: '55%' }}
    padding={{ base: 4, lg: 8 }}
    justifyContent='end'
    bg='white'
    borderRight='1px solid #D6D6D6'
    {...propsFlex}
  >
    <Box maxW={{ base: '100%', lg: 560 }} width='100%'>
      {children}
    </Box>
  </Flex>
)

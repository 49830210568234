import {
  Checkbox,
  Link,
  Alert,
  AlertDescription,
  Textarea
} from '@chakra-ui/react'
import {
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { Controller, useFormContext } from 'react-hook-form'

import { ChatIcon, PhoneIcon } from '@chakra-ui/icons'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { VStack, HStack } from '@tofu/shared/ui/atoms/stack'

import { TTemplateCheckoutForm } from './template-checkout-form.types'

export const TemplateCheckoutForm: TTemplateCheckoutForm = ({
  children,
  formErrors,
  isSubmitButtonDisabled,
  dateDelivery,
  onBlurInputEmail,
  onSubmitCheckoutForm,
  onClickDeliveryDateChange
}) => {
  const { register, handleSubmit, control, watch } = useFormContext()
  const noteForDriverValue = watch('noteForDriver')

  return (
    <>
      <Box mb={6} pb={14} borderBottom='1px solid #D6D6D6'>
        <form onSubmit={handleSubmit(onSubmitCheckoutForm)}>
          <VStack spacing={6} mb={6}>
            <Box width='100%'>
              <Text
                as='h4'
                fontSize='xl'
                color='black'
                fontWeight='bold'
                mb={2}
              >
                Contact
              </Text>
              {/* https://docs.stripe.com/js/elements_object/create_link_authentication_element */}
              <Box mb={3}>
                <Controller
                  name='linkAuthentication'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    // isReadyLinkAuthenticationElement ? (
                    <LinkAuthenticationElement
                      {...field}
                      onBlur={onBlurInputEmail}
                    />
                  )}
                />
                {/* onChange={onChangeUpdateEmail} */}
              </Box>
              <Checkbox {...register('checkboxMarketing')}>
                <Text fontSize='sm'>
                  Get our latest news, product launches and
                  exclusive&nbsp;offers
                </Text>
              </Checkbox>
            </Box>
            {/* https://docs.stripe.com/js/elements_object/create_address_element */}

            <Box width='100%'>
              <HStack mb={2} alignItems='baseline'>
                <Text
                  // variant='link'
                  as='h4'
                  fontSize='xl'
                  color='black'
                  fontWeight='bold'
                  // rightIcon={
                  //   <QuestionOutlineIcon
                  //     fontSize={10}
                  //     position='absolute'
                  //     top='-2px'
                  //     right='-4px'
                  //     // transform='translate(-4px, -2px)'
                  //   />
                  // }
                >
                  Delivery
                </Text>
                {dateDelivery && (
                  <Text fontSize='xs' color='black'>
                    {dateDelivery}
                    <Button
                      ml={1}
                      size='xs'
                      variant='link'
                      color='gray.500'
                      textDecoration='underline'
                      cursor='pointer'
                      onClick={onClickDeliveryDateChange}
                    >
                      (change)
                    </Button>
                  </Text>
                )}
              </HStack>
              <Box mb={3}>
                <AddressElement
                  options={{
                    mode: 'shipping',
                    fields: { phone: 'always' },
                    validation: { phone: { required: 'always' } },
                    display: { name: 'split' },
                    allowedCountries: ['GB']
                  }}
                />
              </Box>
              <Text htmlFor='noteForDriver' as='label' fontSize='0.93rem'>
                Note for driver{' '}
                <Text as='span' fontSize='xs'>
                  (optional)
                </Text>
              </Text>
              <Box position='relative'>
                <Textarea
                  {...register('noteForDriver')}
                  maxLength={50}
                  id='noteForDriver'
                  name='noteForDriver'
                  placeholder="e.g. if I'm out, please hide my box behind our blue fence."
                />
                {noteForDriverValue && noteForDriverValue?.length > 0 && (
                  <Text
                    position='absolute'
                    bottom={'-30px'}
                    right={0}
                    fontSize='sm'
                    textAlign='right'
                    color='gray.500'
                    mt={1}
                  >
                    {50 - (noteForDriverValue?.length || 0)} / 50 characters
                  </Text>
                )}
              </Box>
            </Box>

            <Box width='100%'>
              <Text
                as='h4'
                fontSize='xl'
                color='black'
                fontWeight='bold'
                mb={2}
              >
                Payment
              </Text>

              <PaymentElement
                options={{
                  layout: {
                    type: 'tabs'
                  }
                }}
              />
            </Box>
            {children}
          </VStack>

          <Button
            size='lg'
            width='100%'
            type='submit'
            colorScheme='blue'
            isDisabled={isSubmitButtonDisabled}
          >
            Pay now
          </Button>
          {formErrors && (
            <Alert mt={4} status='warning' py={2} px={4}>
              <AlertDescription fontSize='sm'>{formErrors}</AlertDescription>
            </Alert>
          )}
        </form>
        {/* <Box mt={6}>
          <List fontSize='sm' spacing={2}>
            <ListItem>
              <span aria-label='check' role='img'>
                ✅
              </span>{' '}
              Easy to cancel or reschedule online, anytime
            </ListItem>

            <ListItem>
              <span aria-label='bell' role='img'>
                🔔
              </span>{' '}
              We&apos;ll send reminders before each shipment, so you can amend
              or cancel your order
            </ListItem>

            <ListItem>
              <span aria-label='money' role='img'>
                💰
              </span>{' '}
              You won&apos;t be charged if you cancel before your next order
            </ListItem>
          </List>
        </Box> */}
      </Box>
      <VStack align='start'>
        <Text fontSize='sm'>Have questions? Or need help with your order?</Text>
        <Box width='100%'>
          <HStack spacing={4} align='center'>
            <Button
              size='xs'
              variant='link'
              colorScheme='blue'
              as={Link}
              target='_blank'
              href='&#109;&#97;&#105;&#108;&#116;&#111;&#58;%6B%69%74%63%68%65%6E%40%61%6C%6C%70%6C%61%6E%74%73%2E%63%6F%6D'
              leftIcon={<ChatIcon boxSize={3} />}
            >
              Email
            </Button>
            <Button
              as={Link}
              target='_blank'
              href='tel:02080681700'
              size='xs'
              variant='link'
              colorScheme='blue'
              leftIcon={<PhoneIcon boxSize={3} />}
            >
              Phone
            </Button>
          </HStack>
        </Box>
      </VStack>
    </>
  )
}

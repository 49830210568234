import { FormEvent, KeyboardEvent } from 'react'

import {
  Tag,
  TagLabel,
  TagCloseButton,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  Input,
  Button,
  HStack,
  InputGroup,
  InputRightElement,
  useDisclosure
} from '@chakra-ui/react'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Alert, AlertDescription } from '@tofu/shared/ui/atoms/alert'
import { Text } from '@tofu/shared/ui/atoms/text'
import { DoublePortion } from '@tofu/shared/ui/atoms/icons'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { formatPrice } from '@tofu/shared/utils/format-currency'
import { environment } from '@tofu/shared/constants/environments'
import { TTemplateCheckoutOrderSummary } from './template-checkout-order-summary.types'

const ReferralDashboard = () => (
  <iframe
    frameBorder='0'
    height='610px'
    id='mention-me-dashboard'
    srcDoc={`<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script src="${environment.mentionme_url}/api/v2/refereefind/mm4dd5dff7?situation=checkout&locale=en_GB&implementation=embed"></script>

      </head>
      <body style="overflow:hidden">
        <div id="mmWrapper"></div>
      </body>
    </html>
    `}
    title='mention me'
    width='100%'
  />
)

export const TemplateCheckoutOrderSummary: TTemplateCheckoutOrderSummary = ({
  cartPriceTotal,
  totalSavings,
  cartPriceSubtotal,
  cartDeliveryItemPrice,
  cartDiscountCode,
  onApplyDiscount,
  deliveryCharge,
  onRemoveDiscount,
  isLoadingApplyDiscount,
  cartDiscountTotal,
  setDiscountField,
  discountField,
  isCartTotalPriceBelowMinChargeValue,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmitDiscount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onApplyDiscount(discountField)
  }

  const onClickApplyDiscount = () => {
    onApplyDiscount(discountField)
  }

  return (
    <VStack {...props}>
      {/* 
        Unfortunatly can't use a <form> here 
        This order summary get's nested inside the checkout form on mobile
        and submiting will cause a page reload
      */}
      <Box width='100%' mb={2}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent p={0} bg='transparent' boxShadow='none'>
            <Box
              backgroundImage="url('https://account.allplants.com/img/loading-spinner.gif')"
              backgroundRepeat='no-repeat'
              backgroundPosition='center'
              backgroundSize='30px'
            >
              <ReferralDashboard />
            </Box>
          </ModalContent>
        </Modal>

        <Flex alignItems='center' mt={4} width='100%' mb={3}>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              bg='white'
              placeholder='Discount code'
              value={discountField}
              onChange={(e) => setDiscountField(e.target.value)}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                // Instead of using a form (see comment above)
                if (event.key === 'Enter') {
                  event.preventDefault()
                  onSubmitDiscount(
                    event as unknown as FormEvent<HTMLFormElement>
                  )
                }
              }}
            />
            <InputRightElement width='4.5rem'>
              <Button
                h='1.75rem'
                size='sm'
                type='button'
                onClick={onClickApplyDiscount}
                isDisabled={!discountField}
                isLoading={isLoadingApplyDiscount}
              >
                Apply
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        {cartDiscountCode && (
          <Box width='100%'>
            <Tag
              size='md'
              borderRadius='full'
              colorScheme='green'
              variant='subtle'
              top='0'
            >
              <TagLabel>{cartDiscountCode}</TagLabel>
              <TagCloseButton onClick={onRemoveDiscount} />
            </Tag>
          </Box>
        )}
      </Box>
      <VStack width='100%'>
        <Box width='100%' mb={4}>
          <Button
            size='md'
            width='100%'
            onClick={onOpen}
            variant='outline'
            leftIcon={<DoublePortion />}
          >
            Refered by a friend?
          </Button>
        </Box>
        {/* {dateDelivery && (
          <HStack width='100%' justifyContent='space-between'>
            <Button
              color='black'
              fontWeight='normal'
              fontSize='md'
              variant='link'
              onClick={() => modalDeliveryInfo.show()}
              rightIcon={
                <QuestionOutlineIcon
                  fontSize={12}
                  transform='translate(-4px, -2px)'
                />
              }
            >
              Delivery date
            </Button>
            <Text>{dateDelivery}</Text>
          </HStack>
        )} */}
        <HStack width='100%' justifyContent='space-between'>
          <Text>Delivery fee</Text>

          {cartDeliveryItemPrice ? (
            <Text>{formatPrice(cartDeliveryItemPrice)}</Text>
          ) : (
            <Flex alignItems='center'>
              <Badge color='green'>Free</Badge>
              <Text ml={2} textDecoration='line-through'>
                {formatPrice(deliveryCharge)}
              </Text>
            </Flex>
          )}
        </HStack>
        {/* <Box py={2} width='100%'>
          <Divider />
        </Box> */}
        <HStack width='100%' justifyContent='space-between'>
          <Text>Subtotal:</Text>
          <Text>{formatPrice(cartPriceSubtotal)}</Text>
        </HStack>
        {cartDiscountCode && (
          <HStack width='100%' justifyContent='space-between'>
            <Text>Discount</Text>
            <Text color='green' fontWeight='medium'>
              -{formatPrice(cartDiscountTotal)}
            </Text>
          </HStack>
        )}
        {isCartTotalPriceBelowMinChargeValue && (
          <Alert status='info'>
            <AlertDescription>
              To process your order, a charge will appear on your card and be
              automatically reversed within a few days.
            </AlertDescription>
          </Alert>
        )}

        <HStack width='100%' justifyContent='space-between'>
          <Text fontSize='xl' fontWeight='bold'>
            Total:
          </Text>
          <Text fontSize='xl' fontWeight='bold'>
            {formatPrice(cartPriceTotal)}
          </Text>
        </HStack>
        <Box width='100%' textAlign='right'>
          {totalSavings ? (
            <Badge color='green'>Total savings £{totalSavings}</Badge>
          ) : null}
        </Box>
      </VStack>
    </VStack>
  )
}

/**
 * The naming convention for these is terrible, but it need to keep it consistant with the existing checkout events.
 * 
 *  Should be
 *  // CHECKOUT_STARTED: 'Checkout Started',
    // CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
    // CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
    // CHECKOUT_COMPLETED: 'Checkout Completed',
    // CHECKOUT_COUPON_APPLIED: 'Checkout Coupon Applied',
    // CHECKOUT_COUPON_ENTERED: 'Checkout Coupon Entered',
    // CHECKOUT_COUPON_FAILED: 'Checkout Coupon Denied',
    // CHECKOUT_COUPON_REMOVED: 'Checkout Coupon Removed',
    // CHECKOUT_PAYMENT_INFO_ENTERED: 'Checkout Payment Info Entered'
 */
export const EVENTS_RUDDERSTACK = {
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  ORDER_COMPLETED: 'Order Completed',
  COUPON_APPLIED: 'Coupon Applied',
  COUPON_ENTERED: 'Coupon Entered',
  COUPON_FAILED: 'Coupon Denied',
  COUPON_REMOVED: 'Coupon Removed',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  PAYMENT_FAILED: 'Payment Failed'
}

import { Image, Circle } from '@chakra-ui/react'

import { TCart } from '@tofu/shared/types/cart'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { getServingSize } from '@tofu/shared/utils/cart'
import { formatPrice } from '@tofu/shared/utils/format-currency'

import { TTemplateCheckoutDishes } from './template-checkout-dishes.types'
import { getPortionSizeFromVariantTitle } from './template-checkout-dishes.utils'

export const TemplateCheckoutDishes: TTemplateCheckoutDishes = ({
  items = [],
  ...props
}) => (
  <Box {...props}>
    <VStack spacing={4}>
      {items.map((item) => (
        <Flex
          key={item.id}
          justifyContent='space-between'
          width='100%'
          alignItems='center'
        >
          <Box pr={3} flexShrink={0} position='relative'>
            {item.quantity > 1 && (
              <Circle
                size='19px'
                bg={'black'}
                color='white'
                top='-6px'
                right='6px'
                position='absolute'
                fontSize='xs'
                fontWeight='bold'
                zIndex='100'
              >
                {item.quantity}
              </Circle>
            )}
            <Image
              width='90px'
              height='90px'
              src={`${item.image_url}?w=160`}
              alt='dish'
            />
          </Box>
          <Box width='100%'>
            <Text color='#666666' fontSize='sm'>
              For{' '}
              {getServingSize(
                item,
                getPortionSizeFromVariantTitle(
                  item.variant_title
                ) as TCart['portion_size']
              )}
            </Text>
            <Text fontSize={14} color='#000' fontWeight='medium'>
              {item.product_title}
            </Text>
          </Box>
          <Text fontSize={14} color='#000' fontWeight='medium'>
            {formatPrice(item.price / 100)}
          </Text>
        </Flex>
      ))}
    </VStack>
  </Box>
)

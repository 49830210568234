import { useQuery } from '@tanstack/react-query'
import { client } from '@tofu/shared/utils/axios-client'

import { getShopConstants } from '@tofu/shared/data-access/shop-constants'

export const useQueryConstants = () =>
  useQuery({
    queryKey: ['constants'],
    queryFn: async () => await getShopConstants(client)
  })

import { useContext } from 'react'

import { ProviderRudderstackCheckoutContext } from '@tofu/checkout/providers/provider-rudderstack-checkout'

import type { TRudderStackAnalytics } from './use-rudderstack-analytics-checkout.types'

export const useRudderStackAnalytics: TRudderStackAnalytics = () => {
  const { rudderstackAnalytics, isInitialized } = useContext(
    ProviderRudderstackCheckoutContext
  )

  return { rudderstackAnalytics, isInitialized }
}

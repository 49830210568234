import * as Sentry from '@sentry/nextjs'
import { ErrorBoundary } from 'react-error-boundary'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'
import Head from 'next/head'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { TemplateCheckoutError } from '@tofu/checkout/ui/templates/template-error'
import { themeCheckout } from '@tofu/checkout/theme/theme-checkout'
import { TrackingScriptsCheckout } from '@tofu/checkout/ui/organisms/tracking-scripts-checkout'
import { ProviderRudderstackCheckoutProvider } from '@tofu/checkout/providers/provider-rudderstack-checkout'

const queryClient = new QueryClient()

const Fallback = ({ error, resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  Sentry.captureException(error)

  return (
    <TemplateCheckoutError>
      <VStack width='100%' alignItems='center'>
        <Text>Something went wrong.</Text>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </VStack>
    </TemplateCheckoutError>
  )
}

export default function CheckoutApp({ Component, pageProps }) {
  return (
    <ChakraProvider theme={themeCheckout}>
      <ErrorBoundary FallbackComponent={Fallback}>
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <ProviderRudderstackCheckoutProvider>
              <Head>
                <title>allplants | checkout</title>
                <meta content='noindex,nofollow' name='robots' />
              </Head>
              <main className='app'>
                <Component {...pageProps} />
                <TrackingScriptsCheckout />
              </main>
            </ProviderRudderstackCheckoutProvider>
          </NiceModal.Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

import { Spinner } from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody
} from '@tofu/shared/ui/atoms/modal'

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalLoading = NiceModal.create(() => {
  const modal = useModal()

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    <Modal size='xs' isCentered isOpen={modal.visible}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          <Flex direction='column' align='center' justify='center' gap={4}>
            <Text>Sending to our chefs...</Text>
            <Spinner />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})

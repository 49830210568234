var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"43c6d07b9e82c0e55fb1c6960903b2c7d86f3985"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import * as Integrations from '@sentry/integrations'

const sentryOptionsDev = {
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 1,
  allowUrls: ['staging.bloom.allplants.com'],
  environment: process.env.VERCEL_ENV,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
  profilesSampleRate: 1
} as Sentry.BrowserOptions

const sentryOptionsProd = {
  attachStacktrace: true,
  allowUrls: ['allplants.com'],
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 1,
  ignoreErrors: [
    'Window navigated away',
    'ReferenceError',
    'TypeError',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Window closed',
    'ChunkLoadError',
    'Error: 404 Not Found: No Cart with token',
    'klaviyo'
  ],
  environment: process.env.VERCEL_ENV,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
  profilesSampleRate: 1,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1,
  integrations: [
    new Integrations.Dedupe(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true
    })
  ]
} as Sentry.BrowserOptions

const sentryOptions =
  process.env.NODE_ENV === 'production' ? sentryOptionsProd : sentryOptionsDev

Sentry.init(sentryOptions)

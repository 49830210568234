import includes from 'lodash/includes'
import dayjs from 'dayjs'

import map from 'lodash/fp/map'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'

const getSkuCode = get('sku_code')
const getSkuCodes = map(getSkuCode)
// TODO: test this also share?
export const getSkusFromCartItems = flow(getSkuCodes)

//TODO: come back to this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
export const getCode = (error, codeDiscount, codeDiscountMinimumSpend) => {
  const codeAsString = error.toString()

  const errorCodes = {
    discount_not_found: `Sorry, the code ${codeDiscount} you entered is invalid. Please remove the code and try again.`, // do we want a better message here?
    discount_new_customers_only: `The code ${codeDiscount} can't be used on your order. `,
    discount_minimum_spend: `The code ${codeDiscount} can only be used on orders of ${codeDiscountMinimumSpend}+. Add more deliciousness to your basket & try again.`,
    discount_expired: `The code ${codeDiscount} has expired`,
    discount_valid_on_one_off_only: `Sorry, the code ${codeDiscount} is only valid for one time orders. Please select One Time and try again.`,
    discount_valid_on_subscription_only: `Sorry, the code ${codeDiscount} is only valid for subscription orders. Please select Subscribe & Save and try again.`
  }

  return (
    Object.entries(errorCodes).find(([key]) =>
      includes(codeAsString, key)
    )?.[1] ||
    `Sorry, the code ${codeDiscount} you entered is invalid. Please remove the code and try again.`
  )
}

// TODO test this
export const formatDatesExcluded = map((date: string) =>
  dayjs(date, 'DD-MM-YYYY').toDate()
)

export const getMessageFromDeclineCode = (code: string) => {
  const errorMessages = {
    generic_decline:
      'Your card has been declined. Please review your payment details or try a different payment method.',
    lost_card:
      'Your card has been declined and cannot be used for this payment. Please use an different payment method.',
    stolen_card:
      'Your card has been declined and cannot be used for this payment. Please use an different payment method.',
    insufficient_funds:
      'Your card has insufficient funds. Please check your balance or use a different payment method.'
  }
  return (
    (errorMessages as Record<string, string>)[code] ||
    'An error occurred processing your payment. Please try again.'
  )
}

export const getMessageFromErrorCode = (code: string) => {
  const errorMessages = {
    processing_error:
      'An error occurred while processing your card. Please wait a moment and try again.',
    expired_card:
      'Your card has expired. Please update your payment details with a valid card and try again.',
    incorrect_number:
      'Your card number is incorrect. Please update your card number and try again.'
  }
  return (
    (errorMessages as Record<string, string>)[code] ||
    'An error occurred processing your payment. Please try again.'
  )
}

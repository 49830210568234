import { isValid, parse } from 'date-fns'
import { TCart } from '@tofu/shared/types/cart'

// TODO test this
export const getFirstString = (input: string | string[]): string => {
  if (typeof input === 'string') {
    return input
  }
  return input[0] || ''
}

export const isValidDateFormat = (dateString: string): boolean => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date())
  return isValid(parsedDate)
}

export function penceToPounds(pennies: number): number {
  return pennies / 100
}

// TODO test this and compare with the existing one
export const getCommonOrderData = (cart: TCart) => ({
  value: penceToPounds(cart.total_price),
  revenue: penceToPounds(cart.total_price),
  discount: penceToPounds(cart.total_discount),
  coupon: cart.discount_codes?.[0].code ?? null,
  currency: 'gbp',
  products: cart.items.map(
    ({
      image_url,
      price,
      product_id,
      product_title,
      product_type,
      quantity,
      sku_code,
      variant_title
    }) => ({
      brand: 'allplants',
      category: product_type,
      image_url,
      name: product_title,
      price: penceToPounds(price),
      product_id,
      quantity,
      sku: sku_code,
      variant: variant_title
    })
  ),
  subscription_type:
    cart.subscription_type !== 'ONE_TIME' ? 'SUBSCRIPTION' : 'ONE_OFF'
})

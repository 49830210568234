/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
 *
 * Tracking scripts imported from the old checkout
 * They're messy AF but needed to port them over to keep the
 * tracking the same.
 *
 * Will need to be refactored in the future and all moved
 * into simple rudderstack events.
 */

import { fill, times } from 'lodash'
import sha256 from 'crypto-js/sha256'
import { TCart } from '@tofu/shared/types/cart'
// import { TOrderItem } from '@tofu/shared/types/order'
import { getDeliveryItemFromCartItems } from '@tofu/shared/utils/cart'

export const trackEvent = (name: string, data = {}, permanentData = {}) => {
  void addData({ log_data: undefined }) // reset data
  return sendEvent('log', {
    log_name: name,
    log_data: data,
    log_perm_data: permanentData
  })
}

export default function createPromiseWithTimeout(
  cb,
  timeoutMs,
  silentTimeout = false
) {
  return new Promise((resolve, reject) => {
    let returned = false
    const timeoutHandle = setTimeout(() => {
      if (!returned) {
        returned = true
        if (silentTimeout) resolve()
        else reject()
      }
    }, timeoutMs)
    cb(() => {
      if (!returned) {
        returned = true
        clearTimeout(timeoutHandle)
        resolve()
      }
    })
  })
}

export const addData = (content) => {
  try {
    return createPromiseWithTimeout(
      (cb) =>
        // eslint-disable-next-line no-undef
        dataLayer.push({
          // This clears data to prevent some merging in SPAs
          // https://www.simoahava.com/analytics/two-simple-data-model-tricks/
          _clear: true,
          ...content,
          eventCallback: () => {
            if (content.eventCallback) content.eventCallback()
            cb()
          }
        }),
      1000,
      true
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to add data for GTM:', error)
    return Promise.resolve()
  }
}

export const sendEvent = (eventName: string, content) => {
  try {
    const data = {
      ...content,
      event: eventName
    }
    return addData(data)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to send event ${eventName} for GTM:`, error)
    return Promise.resolve()
  }
}

export const sendEcommerce = (eventName, data) =>
  Promise.all([
    sendEvent(eventName, data),
    // Clear the ecommerce so it doesn't get sent with other GA events
    // (`_clear: true` only prevents merging, it doesn't remove the object
    // after pushing to the data layer)
    addData({ ecommerce: undefined })
  ])

export const setUserId = (id) => {
  // Amplitude requires we must always send ID as minimum 5 characters
  const idTo5chars = id.toLocaleString('en-GB', {
    minimumIntegerDigits: 5,
    useGrouping: false
  })
  sendEvent('user.id', {
    user_id: idTo5chars
  })
}

export const setUserData = (data) =>
  sendEvent('user.data', {
    user_data: data
  })

// TODO: test this
export const getRevenueItems = (cart: TCart) => {
  const revenueItems = cart.items.map((item) => ({
    price: item.price / 100,
    quantity: item.quantity || 1,
    productId: item.product_id,
    type: 'purchase'
  }))

  if (cart?.total_discount) {
    revenueItems.push({
      price: cart.total_discount ? -cart.total_discount / 100 : 0,
      quantity: 1,
      productId: `${cart.discount_codes?.[0].code}` ?? '',
      type: 'discount'
    })
  }

  const deliveryCost = getDeliveryItemFromCartItems(cart.items)

  if (deliveryCost) {
    revenueItems.push({
      price: deliveryCost.price / 100,
      quantity: 1,
      productId: 'delivery',
      type: 'delivery'
    })
  }
  return revenueItems
}

export const trackRevenue = (cart: TCart) => {
  const revenueItems = getRevenueItems(cart)

  Promise.all(
    revenueItems.map((item) =>
      sendEvent('revenue', {
        revenue_data: item
      })
    )
  )
}

export function getVariantIds(items) {
  return items
    .map(({ quantity, variant_id }) =>
      quantity === 1 ? variant_id : fill(times(quantity), variant_id)
    )
    .join(',')
}

export function getCountForProductType(items, product_type) {
  if (!items) return 0
  return items.reduce((count, item) => {
    if (item?.product_type === product_type) {
      const newCount = count + (item?.quantity || 0)
      return newCount
    }
    return count
  }, 0)
}

export function getCountForMeals(items) {
  const mainMealsCount = getCountForProductType(items, 'MEAL')
  const pizzaCount = getCountForProductType(items, 'PIZZA')
  return mainMealsCount + pizzaCount
}

export function getCountForExtras(items) {
  const treatCount = getCountForProductType(items, 'TREAT')
  const smoothieCount = getCountForProductType(items, 'SMOOTHIE')
  const breakfastCount = getCountForProductType(items, 'BREAKFAST')
  const sideCount = getCountForProductType(items, 'SIDE')
  return treatCount + breakfastCount + smoothieCount + sideCount
}

export function getCountForAllItems(items) {
  if (!items) return 0
  return items.reduce((count, item) => count + (item?.quantity || 0), 0)
}

export function getCountForOther(items) {
  const allItemCount = getCountForAllItems(items)
  const mealCount = getCountForMeals(items)
  const extrasCount = getCountForExtras(items)
  return allItemCount - mealCount - extrasCount
}

export const sendMarketingEvent = (eventName, data) =>
  sendEvent('marketing', {
    marketing_event_name: eventName,
    marketing_event_data: data
  })

type TFormValues = {
  firstName: string
  lastName: string
  city: string
  email: string
  address1: string
  address2: string
  dateDelivery: string
  postalCode: string
  acceptsMarketing: string
}

export const getTrackPurchaseSuccessData = (
  cart: TCart,
  order,
  formValues: TFormValues
) => ({
  orderId: order.id,
  email: formValues.email,
  emailSHA256: sha256(formValues.email).toString(),
  firstName: formValues.firstName,
  lastName: formValues.lastName,
  frequency: cart.frequency,
  subscriptionType:
    cart.subscription_type !== 'ONE_TIME' ? 'SUBSCRIPTION' : 'ONE_OFF',

  deliveryDate: formValues.dateDelivery,
  isFirstOrder: '?', //TODO: add this
  totalDiscount: cart.total_discount / 100,
  totalPrice: cart.total_price / 100,
  discountCode: cart.discount_codes?.[0]?.code,
  // shopifyOrderName: order.shopifyOrderName, // TODO: do we need this?
  variantIds: getVariantIds(cart.items),
  mealCount: getCountForMeals(cart.items),
  extrasCount: getCountForExtras(cart.items),
  otherCount: getCountForOther(cart.items)
})

import { z } from 'zod'
import { listOfPostalInvalidCodes } from './type-stripe-request-checkout.constants'

export const CheckoutFormValues = z.object({
  accepts_marketing: z.boolean().optional(),
  noteForDriver: z.string().optional(),
  address_1: z.string().min(1, 'Address is required'),
  city: z.string().min(1, 'City is required'),
  deliver_at: z.string().min(1, 'Delivery date is required'),
  // deliver_at: z.date(),
  email: z.string().email('Invalid email address'),
  phone: z.string().regex(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number'),
  postal_code: z
    .string()
    .min(1, 'Postal code is required')
    .refine(
      (val) =>
        ![...listOfPostalInvalidCodes].some((code) =>
          val.toUpperCase().startsWith(code)
        ),
      {
        params: { code: 'invalid_postcode' }
      }
    ),
  session_cart_token: z.string().min(1, 'Cart token is required')
})

export type TCheckoutFormValues = z.infer<typeof CheckoutFormValues>

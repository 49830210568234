import { useEffect, useState } from 'react'

import { TCart } from '@tofu/shared/types/cart'
import { EVENTS_RUDDERSTACK } from '@tofu/checkout/constants/constants-checkout'
import { useRudderStackAnalytics } from '@tofu/checkout/hooks/use-rudderstack-analytics-checkout'

import { getCommonOrderData } from './page-checkout.utils'

export const usePageCheckoutTracking = ({ cart }: { cart?: TCart }) => {
  const { rudderstackAnalytics, isInitialized } = useRudderStackAnalytics()

  const [hasTrackedCheckoutStarted, setHasTrackedCheckoutStarted] =
    useState(false)

  /**
   * Checkout Started
   */
  useEffect(() => {
    if (!isInitialized) return
    if (hasTrackedCheckoutStarted || !cart) return

    rudderstackAnalytics?.track(
      EVENTS_RUDDERSTACK.CHECKOUT_STARTED,
      getCommonOrderData(cart)
    )
    setHasTrackedCheckoutStarted(true)
  }, [rudderstackAnalytics, cart, hasTrackedCheckoutStarted, isInitialized])
}

import { IconButton, Link } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Stack } from '@tofu/shared/ui/atoms/stack'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { AllplantsLogo, BasketIcon } from '@tofu/shared/ui/atoms/icons'

export const LayoutCheckoutHeader = ({ shouldShowBasketIcon = false }) => (
  <Box py={5} px={3} borderBottom='1px solid #D6D6D6'>
    <Stack direction='row' spacing={0} align='center' justifyContent='center'>
      <Flex
        width={{ base: '100%', lg: '55%' }}
        justifyContent='end'
        px={{ base: 4, lg: 8 }}
      >
        <Box maxW={{ base: '100%', lg: 560 }} width='100%'>
          <Link href='https://www.allplants.com'>
            <AllplantsLogo height='30px' />
          </Link>
        </Box>
      </Flex>

      <Flex order={{ base: 0, lg: 1 }} width={{ base: '100%', lg: '45%' }}>
        <Flex width='100%' px={{ base: 2, lg: 8 }} justifyContent='start'>
          <Flex
            maxW={{ base: '100%', lg: 420 }}
            width='100%'
            justifyContent='flex-end'
            position='relative'
          >
            {shouldShowBasketIcon && (
              <Link
                href='https://www.allplants.com/shop'
                position='absolute'
                top={'-20px'}
                right={'-15px'}
              >
                <IconButton
                  variant='ghost'
                  width={'auto'}
                  as={'span'}
                  aria-label='return to shop'
                  icon={<BasketIcon fill='black' />}
                />
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  </Box>
)

import { useEffect, useState } from 'react'
import enGB from 'date-fns/locale/en-GB'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import DatePicker, { registerLocale } from 'react-datepicker'

import { DELIVERY_CUTOFF_HOUR } from '@tofu/shared/constants/date-time'

import { TDeliveryCalendar } from './delivery-calendar.types'
import 'react-datepicker/dist/react-datepicker.css'

// Always block out Monday and Sundays by default in calendar, as we don't deliver on those days.
const undeliverableDays = new Set([0, 1])

const isDeliverableDay = (date: Date) => {
  const day = date.getDay()
  return !undeliverableDays.has(day)
}

export const DeliveryCalendar: TDeliveryCalendar = ({
  excludedDates,
  handleChange,
  minDate,
  maxDate,
  selectedDate
}) => {
  const [defaultMin, setDefaultMin] = useState<Date>()
  const [defaultMax, setDefaultMax] = useState<Date>()

  useEffect(() => {
    // Set default minimal date as today + 3
    const now = dayjs()
    const isBeforeCutoff = now.hour() < DELIVERY_CUTOFF_HOUR
    const min = dayjs(dayjs().add(isBeforeCutoff ? 1 : 2, 'day')).toDate()

    // Set default maximum date as today + 2 months
    const max = dayjs(dayjs().add(60, 'day')).toDate()

    setDefaultMax(max)
    setDefaultMin(min)
  }, [])

  dayjs.extend(customParseFormat)
  registerLocale('en-GB', enGB)

  return (
    <>
      {/* @ts-expect-error async component */}
      <DatePicker
        excludeDates={excludedDates}
        filterDate={isDeliverableDay}
        inline
        locale='en-GB'
        maxDate={maxDate || defaultMax}
        minDate={minDate || defaultMin}
        onChange={(date: Date) => handleChange(date)}
        selected={selectedDate}
      />
    </>
  )
}

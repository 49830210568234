import times from 'lodash/times'
import { Divider, Skeleton } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Flex } from '@tofu/shared/ui/atoms/flex'

import {
  LayoutCheckout,
  LayoutCheckoutHeader,
  LayoutCheckoutContainer,
  LayoutCheckoutContentMain,
  LayoutCheckoutContentAside
} from '@tofu/checkout/ui/organisms/layout-checkout'

import { TTemplateCheckoutLoading } from './template-loading.types'

export const TemplateCheckoutLoading: TTemplateCheckoutLoading = () => (
  <LayoutCheckout>
    <LayoutCheckoutHeader />

    <LayoutCheckoutContainer>
      <LayoutCheckoutContentMain>
        <VStack alignItems='flex-start' spacing={10} divider={<Divider />}>
          <VStack width='100%' alignItems='flex-start' spacing={3}>
            <Skeleton width={'120px'} height={5} />
            <Skeleton width='100%' height={4} />
            <Skeleton width='100%' height={4} />
          </VStack>

          <VStack width='100%' alignItems='flex-start' spacing={3}>
            <Skeleton width={'120px'} height={5} />
            <Skeleton width='100%' height={4} />
            <Skeleton width='100%' height={4} />
          </VStack>
        </VStack>
      </LayoutCheckoutContentMain>

      <LayoutCheckoutContentAside>
        <Box px={{ base: 4, lg: 8 }} py={{ base: 2, lg: 8 }}>
          <VStack spacing={3} alignItems='flex-start'>
            {times(6, (index) => (
              <Flex
                key={index}
                justifyContent='space-between'
                width='100%'
                alignItems='center'
              >
                <Box pr={3}>
                  <Skeleton width='90px' height='80px' />
                </Box>
                <VStack spacing={2} width='100%' alignItems='flex-start'>
                  <Skeleton width={'60%'} height={5} />
                  <Skeleton width={30} height={5} />
                </VStack>
                <Text
                  as={Skeleton}
                  fontSize={14}
                  color='#000'
                  fontWeight='medium'
                />
              </Flex>
            ))}
          </VStack>
        </Box>
      </LayoutCheckoutContentAside>
    </LayoutCheckoutContainer>
  </LayoutCheckout>
)

import { captureException } from '@sentry/nextjs'

import {
  DeliveryDaysResponse,
  TDeliveryDaysResponse
} from './delivery-days.types'

export const isValidDeliveryDaysResponse = (
  deliveryDaysResponse: unknown
): deliveryDaysResponse is TDeliveryDaysResponse => {
  const result = DeliveryDaysResponse.safeParse(deliveryDaysResponse)

  if ('error' in result) {
    captureException(
      new Error(`Failed to parse delivery days. Error: ${result.error}`)
    )
    return false
  }

  return true
}

/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Sentry from '@sentry/browser'
import { useToast } from '@chakra-ui/react'
import { addHours, format } from 'date-fns'
import { useState, useMemo } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import { useFormContext } from 'react-hook-form'

import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement
} from '@stripe/react-stripe-js'

// import { StripeExpressCheckoutElementClickEvent } from '@stripe/stripe-js'
import { useMutationUpdateCart } from '@tofu/checkout/hooks/use-mutation-update-cart'
import { useDeliveryDays } from '@tofu/checkout/hooks/use-query-delivery-days'
import { useRudderStackAnalytics } from '@tofu/checkout/hooks/use-rudderstack-analytics-checkout'
import {
  CheckoutFormValues,
  TCheckoutFormValues
} from '@tofu/checkout/types/type-stripe-request-checkout'
import { EVENTS_RUDDERSTACK } from '@tofu/checkout/constants/constants-checkout'
import {
  getCode,
  getSkusFromCartItems,
  formatDatesExcluded,
  getMessageFromErrorCode,
  getMessageFromDeclineCode
} from './template-checkout.utils'
import { TemplateCheckoutModalLoading } from './template-checkout-modal-loading'
import { TemplateCheckoutModalPaymentError } from './template-checkout-modal-payment-error'
import { TemplateCheckoutModalDiscountError } from './template-checkout-modal-discount-error'
import { TemplateCheckoutModalDeliveryAddressError } from './template-checkout-modal-delivery-address-error'

import {
  TUseTemplateCheckoutStipe,
  TUseTemplateCheckoutDiscounts,
  TUseTemplateCheckoutDeliveryDates
} from './template-checkout.types'

dayjs.extend(dayOfYear)

const checkDiscount = (email) => {
  console.log('running discount check on', email)
}

export const useTemplateCheckoutDiscounts: TUseTemplateCheckoutDiscounts = ({
  cart,
  cartToken
}) => {
  const modalDiscountError = useModal(TemplateCheckoutModalDiscountError)
  const [discountField, setDiscountField] = useState('')
  const { rudderstackAnalytics } = useRudderStackAnalytics()

  const { mutateAsync: removeDiscount } = useMutationUpdateCart({
    cartToken
  })

  const { mutateAsync: applyDiscount, isLoading: isLoadingApplyDiscount } =
    useMutationUpdateCart({
      cartToken,
      onSuccess: () => {
        setDiscountField('')
        rudderstackAnalytics?.track(EVENTS_RUDDERSTACK.COUPON_APPLIED, {
          cart_id: cart?.id,
          coupon_id: discountField,
          discount: cart?.total_discount
        })
      },
      onError: (error, data) => {
        const minimumSpend = data?.discount_codes?.[0]?.minimum_spend

        rudderstackAnalytics?.track(EVENTS_RUDDERSTACK.COUPON_FAILED, {
          cart_id: cart?.id,
          coupon_id: discountField
        })

        const description = getCode(
          error,
          data?.discount_codes?.[0]?.code,
          minimumSpend
        )

        modalDiscountError.show({ description })

        setDiscountField('')
      }
    })

  const handleOnApplyDiscount = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    discountField &&
      applyDiscount({ discount_codes: [{ code: discountField }] })
  }

  const handleOnRemoveDiscount = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    // do this before removing the discount so we can track the removed code
    rudderstackAnalytics?.track(EVENTS_RUDDERSTACK.COUPON_REMOVED, {
      cart_id: cart?.id,
      coupon_id: discountField,
      discount: cart?.total_discount
    })

    removeDiscount({ discount_codes: [{ code: '' }] })
  }

  return {
    discountField,
    checkDiscount,
    setDiscountField,
    handleOnApplyDiscount,
    handleOnRemoveDiscount,
    isLoadingApplyDiscount
  }
}

export const useTemplateCheckoutStipe: TUseTemplateCheckoutStipe = ({
  cartToken,
  dateDelivery
}) => {
  const toast = useToast()
  const stripe = useStripe()
  const { getValues } = useFormContext()
  const { rudderstackAnalytics } = useRudderStackAnalytics()
  const modalLoading = useModal(TemplateCheckoutModalLoading)
  const modalPaymentError = useModal(TemplateCheckoutModalPaymentError)
  const modalDeliveryAddressError = useModal(
    TemplateCheckoutModalDeliveryAddressError
  )
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState()

  const handleError = (error) => {
    setIsLoading(false)
    setFormErrors(error.message)
  }

  const handleOnSubmitCheckoutForm = async () => {
    const { linkAuthentication, noteForDriver, checkboxMarketing } = getValues()
    const email = linkAuthentication?.value?.email

    setFormErrors(null)

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // TODO: Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    // Retrieve shipping address data from AddressElement
    const shippingAddressElement = elements.getElement(AddressElement)
    const shippingAddressData = await shippingAddressElement.getValue()
    const {
      firstName: firstNameForm,
      lastName,
      address: shippingAddress,
      phone,
      name
    } = shippingAddressData.value

    const firstName = firstNameForm || name

    // identify the user with their email
    const anonymousId = rudderstackAnalytics?.getAnonymousId()
    anonymousId &&
      rudderstackAnalytics?.identify(anonymousId, {
        address: {
          city: shippingAddress?.city,
          country: 'United Kingdom',
          street: shippingAddress?.line2
            ? `${shippingAddress?.line1}, ${shippingAddress?.line2}`
            : shippingAddress?.line1,
          postalCode: shippingAddress?.postal_code
        },
        firstName,
        lastName,
        email,
        phone
      })

    // Retrieve billing address data from PaymentElement
    const paymentElement = elements.getElement(PaymentElement)
    const billingDetails =
      paymentElement._paymentRequestData?.billing_details || {}
    const billingAddress = billingDetails?.address

    const requestBody = {
      accepts_marketing: checkboxMarketing,
      address_1: shippingAddress?.line1,
      city: shippingAddress?.city,
      deliver_at: format(
        addHours(dateDelivery, 1),
        "yyyy-MM-dd'T'00:00:00.000'Z'"
      ),
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone,
      postal_code: shippingAddress.postal_code,
      session_cart_token: cartToken
    } as TCheckoutFormValues

    if (noteForDriver) {
      requestBody.note = noteForDriver
    }

    if (shippingAddress?.line2) {
      requestBody.address_2 = shippingAddress.line2
    }

    if (billingAddress?.postal_code) {
      requestBody.billing_postal_code = billingAddress.postal_code
    }

    try {
      CheckoutFormValues.parse(requestBody)
    } catch (error) {
      if (error.errors[0]?.params?.code === 'invalid_postcode') {
        modalDeliveryAddressError.show()
        return
      }

      const listOfErrors = error.errors.map((error) => error.message)

      toast({
        description: listOfErrors.join('\n'),
        status: 'error',
        variant: 'subtle',
        duration: 3000,
        isClosable: true
      })

      return
    }

    modalLoading.show()

    let res
    try {
      res = await fetch(`${process.env['API_BASE_URL']}/payment_intents`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })

      if (!res.ok) {
        Sentry.setContext('order', {
          cart: cartToken
        })
        Sentry.setUser({ email })
        Sentry.captureException(
          new Error(`Payment Intent Error: ${res.status} ${res.statusText}`)
        )

        const responseJson = await res?.json()

        const errorMessage =
          responseJson?.errors?.[0]?.user_message ||
          'There was an error please can you try again'

        modalLoading.hide()
        modalPaymentError.show({ error: errorMessage })
        setIsLoading(false)
        return
      }
    } catch (error) {
      Sentry.setContext('order', {
        email
      })
      Sentry.setUser({ email })
      Sentry.captureException(error)
    }

    const responseJson = await res?.json()
    const orderId = responseJson.data[0].id
    const customerId = responseJson.data[0].customer_id
    const totalDiscount = responseJson.data[0]?.total_discount || 0
    const discountCode = responseJson.data[0]?.discount_codes?.[0] || ''
    const clientSecret = responseJson.data[0]?.payment_intent_client_secret
    const verifiedAt = responseJson.data[0]?.customer?.verified_at

    const params = new URLSearchParams({
      orderId,
      customerId,
      hasVerified: verifiedAt ? 1 : 0,
      discountCode,
      totalDiscount,
      email: email,
      token: cartToken,
      firstName: firstName,
      lastName: lastName,
      address1: shippingAddress?.line1,
      dateDelivery: dateDelivery?.toUTCString(),
      postalCode: shippingAddress?.postal_code,
      acceptsMarketing: checkboxMarketing?.toString(),
      city: shippingAddress?.city && shippingAddress?.city,
      address2: shippingAddress?.line2 ? shippingAddress?.line2 : ''
    })

    // Remove any params with undefined values
    for (const [key, value] of params.entries()) {
      if (value === undefined) {
        params.delete(key)
      }
    }

    // TODO: change this to base url when we go live
    const return_url = `${
      window.location.origin
    }/confirmation?${params.toString()}`

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: return_url
      }
    })

    let message = ''

    if (error) {
      message = error?.decline_code
        ? getMessageFromDeclineCode(error?.decline_code)
        : getMessageFromErrorCode(error?.code)

      rudderstackAnalytics?.track(EVENTS_RUDDERSTACK.PAYMENT_FAILED, {
        orderId: orderId,
        message: message,
        code: error?.code,
        declineCode: error?.decline_code,
        paymentMethod: error?.decline_code || error?.code
      })

      modalLoading.hide()
      modalPaymentError.show({ error: message })
    }
  }

  return {
    formErrors,
    isStripeReady: !!stripe,
    isStripeLoading: isLoading,
    onSubmitCheckoutForm: handleOnSubmitCheckoutForm
  }
}

export const useTemplateCheckoutDeliveryDates: TUseTemplateCheckoutDeliveryDates =
  ({ items }) => {
    const skus = getSkusFromCartItems(items)

    const { data } = useDeliveryDays(skus) || {}
    const { excluded_dates, max, min, first_date } = data || {}

    dayjs.extend(customParseFormat)

    return useMemo(() => {
      const dateMax = dayjs(max, 'DD-MM-YYYY').toDate()
      const dateMin = dayjs(min, 'DD-MM-YYYY').toDate()
      const dateFirst = dayjs(first_date, 'DD-MM-YYYY').toDate()
      const datesExcluded = formatDatesExcluded(excluded_dates)

      return {
        dateMin,
        dateMax,
        dateFirst,
        datesExcluded
      }
    }, [excluded_dates, max, min, first_date])
  }

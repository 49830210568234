import { Center } from '@chakra-ui/react'
import {
  LayoutCheckout,
  LayoutCheckoutHeader,
  LayoutCheckoutContainer
} from '@tofu/checkout/ui/organisms/layout-checkout'

import { TTemplateCheckoutError } from './template-error.types'

export const TemplateCheckoutError: TTemplateCheckoutError = ({ children }) => (
  <LayoutCheckout>
    <LayoutCheckoutHeader />

    <LayoutCheckoutContainer>
      <Center height='70vh' width='100%'>
        {children}
      </Center>
    </LayoutCheckoutContainer>
  </LayoutCheckout>
)

import { useQueryClient, useMutation } from '@tanstack/react-query'

import { updateCart } from '@tofu/shared/data-access/cart'
import { TCart } from '@tofu/shared/types/cart'

import { TUseMutationUpdateCart } from './use-mutation-update-cart.types'

export const useMutationUpdateCart: TUseMutationUpdateCart = ({
  cartToken,
  ...options
}) => {
  const queryClient = useQueryClient()

  const mutationFn = async (cartUpdate: TCart) => {
    try {
      const response = await updateCart(cartToken, cartUpdate)
      queryClient.invalidateQueries(['getCart'])
      return response
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw new Error('error applying discount', error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  return useMutation({
    mutationFn,
    ...options
  })
}

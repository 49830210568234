import { environment } from '@tofu/shared/constants/environments'
import {
  isValidDeliveryDaysResponse,
  TDeliveryDays
} from '@tofu/shared/types/delivery-days'

export const getDeliveryDays = async (skus: string): Promise<TDeliveryDays> => {
  try {
    const response = await fetch(
      `${environment.api_base_url}/delivery_days?skus=${skus}&check_second_shipping_limit=true`
    )
    const json: unknown = await response.json()

    if (!isValidDeliveryDaysResponse(json)) {
      throw new Error('Invalid delivery days')
    }

    return json.data[0]
  } catch (error) {
    throw new Error(`Failed to get delivery days: ${error}`)
  }
}

import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Text } from '@tofu/shared/ui/atoms/text'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from '@tofu/shared/ui/atoms/modal'
import { TTemplateCheckoutModalDeliveryDateErrorProps } from './template-checkout-modal-delivery-date-error.types'

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalDeliveryDateError = NiceModal.create(
  ({ date }: TTemplateCheckoutModalDeliveryDateErrorProps) => {
    const modal = useModal()

    return (
      <Modal
        size='md'
        isCentered
        isOpen={modal.visible}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Oops, we couldn't complete your payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <Text mb={4}>
              There was an issue with the delivery date. We&apos;ve
              automatically updated it to{' '}
              <Text as='span' fontWeight='bold'>
                {' '}
                {date}
              </Text>
              . Please review your order and try again.
            </Text>

            <Text>Please review your order and try again.</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)

import { useEffect } from 'react'

import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { TUseLayoutUtilsCheckout } from './use-layout-utils-checkout.types'

export const useLayoutUtilsCheckout: TUseLayoutUtilsCheckout = () => {
  const {
    isOpen: isOpenOrderSummary,
    onToggle: onToggleOrderSummary,
    onClose: onCloseOrderSummary,
    onOpen: onOpenOrderSummary
  } = useDisclosure({
    defaultIsOpen: true
  })

  const shouldCollapseOrderSummary = useBreakpointValue(
    {
      base: true,
      sm: true,
      md: true,
      lg: false,
      xl: false
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: 'base'
    }
  )

  useEffect(() => {
    if (shouldCollapseOrderSummary) {
      onCloseOrderSummary()
    } else {
      onOpenOrderSummary()
    }
  }, [onCloseOrderSummary, onOpenOrderSummary, shouldCollapseOrderSummary])

  return {
    isOpenOrderSummary,
    onToggleOrderSummary
  }
}

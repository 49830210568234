// these are just the start of the post code, not the full values
export const listOfPostalInvalidCodes = new Set([
  'AB31',
  'AB32',
  'AB33',
  'AB34',
  'AB35',
  'AB36',
  'AB37',
  'AB38',
  'AB41',
  'AB42',
  'AB43',
  'AB44',
  'AB45',
  'AB46',
  'AB47',
  'AB48',
  'AB49',
  'AB50',
  'AB51',
  'AB52',
  'AB53',
  'AB54',
  'AB55',
  'AB56',
  'EI1',
  'EI2',
  'EI3',
  'EI4',
  'EI5',
  'EI6',
  'EI7',
  'EI8',
  'EI9',
  'EI10',
  'EI11',
  'EI12',
  'EI13',
  'EI14',
  'EI15',
  'EI16',
  'EI17',
  'EI18',
  'EI19',
  'EI20',
  'EI21',
  'EI22',
  'EI23',
  'EI24',
  'EI25',
  'EI26',
  'EI27',
  'EI28',
  'EI29',
  'EI30',
  'EI31',
  'EI32',
  'EI33',
  'EI34',
  'EI35',
  'EI36',
  'EI37',
  'EI38',
  'EI39',
  'EI40',
  'EI41',
  'EI42',
  'EI43',
  'EI44',
  'EI45',
  'EI46',
  'EI47',
  'EI48',
  'EI49',
  'EI50',
  'EI51',
  'EI52',
  'EI53',
  'EI54',
  'EI55',
  'EI56',
  'EI57',
  'EI58',
  'EI59',
  'EI60',
  'EI61',
  'EI62',
  'EI63',
  'EI64',
  'EI65',
  'EI66',
  'EI67',
  'EI68',
  'EI69',
  'EI70',
  'EI71',
  'EI72',
  'EI73',
  'EI74',
  'EI75',
  'EI76',
  'EI77',
  'EI78',
  'EI79',
  'EI80',
  'EI81',
  'EI82',
  'EI83',
  'EI84',
  'EI85',
  'EI86',
  'EI87',
  'EI88',
  'EI89',
  'EI90',
  'EI91',
  'EI92',
  'EI93',
  'EI94',
  'EI95',
  'EI96',
  'EI97',
  'EI98',
  'EI99',
  'FK17',
  'FK18',
  'FK19',
  'FK20',
  'FK21',
  'G83',
  'GY9',
  'HS1',
  'HS2',
  'HS3',
  'HS4',
  'HS5',
  'HS6',
  'HS7',
  'HS8',
  'HS9',
  'IM1',
  'IM2',
  'IM3',
  'IM4',
  'IM5',
  'IM6',
  'IM7',
  'IM8',
  'IM9',
  'IM10',
  'IM11',
  'IM12',
  'IM13',
  'IM14',
  'IM15',
  'IM16',
  'IM17',
  'IM18',
  'IM19',
  'IM20',
  'IM21',
  'IM22',
  'IM23',
  'IM24',
  'IM25',
  'IM26',
  'IM27',
  'IM28',
  'IM29',
  'IM30',
  'IM31',
  'IM32',
  'IM33',
  'IM34',
  'IM35',
  'IM36',
  'IM37',
  'IM38',
  'IM39',
  'IM40',
  'IM41',
  'IM42',
  'IM43',
  'IM44',
  'IM45',
  'IM46',
  'IM47',
  'IM48',
  'IM49',
  'IM50',
  'IM51',
  'IM52',
  'IM53',
  'IM54',
  'IM55',
  'IM56',
  'IM57',
  'IM58',
  'IM59',
  'IM60',
  'IM61',
  'IM62',
  'IM63',
  'IM64',
  'IM65',
  'IM66',
  'IM67',
  'IM68',
  'IM69',
  'IM70',
  'IM71',
  'IM72',
  'IM73',
  'IM74',
  'IM75',
  'IM76',
  'IM77',
  'IM78',
  'IM79',
  'IM80',
  'IM81',
  'IM82',
  'IM83',
  'IM84',
  'IM85',
  'IM86',
  'IM87',
  'IM88',
  'IM89',
  'IM90',
  'IM91',
  'IM92',
  'IM93',
  'IM94',
  'IM95',
  'IM96',
  'IM97',
  'IM98',
  'IM99',
  'IV1',
  'IV2',
  'IV3',
  'IV4',
  'IV5',
  'IV6',
  'IV7',
  'IV8',
  'IV9',
  'IV10',
  'IV11',
  'IV12',
  'IV13',
  'IV14',
  'IV15',
  'IV16',
  'IV17',
  'IV18',
  'IV19',
  'IV20',
  'IV21',
  'IV22',
  'IV23',
  'IV24',
  'IV25',
  'IV26',
  'IV27',
  'IV28',
  'IV29',
  'IV30',
  'IV31',
  'IV32',
  'IV33',
  'IV34',
  'IV35',
  'IV36',
  'IV37',
  'IV38',
  'IV39',
  'IV40',
  'IV41',
  'IV42',
  'IV43',
  'IV44',
  'IV45',
  'IV46',
  'IV47',
  'IV48',
  'IV49',
  'IV50',
  'IV51',
  'IV52',
  'IV53',
  'IV54',
  'IV55',
  'IV56',
  'IV57',
  'IV58',
  'IV59',
  'IV60',
  'IV61',
  'IV62',
  'IV63',
  'IV64',
  'IV65',
  'IV66',
  'IV67',
  'IV68',
  'IV69',
  'IV70',
  'IV71',
  'IV72',
  'IV73',
  'IV74',
  'IV75',
  'IV76',
  'IV77',
  'IV78',
  'IV79',
  'IV80',
  'IV81',
  'IV82',
  'IV83',
  'IV84',
  'IV85',
  'IV86',
  'IV87',
  'IV88',
  'IV89',
  'IV90',
  'IV91',
  'IV92',
  'IV93',
  'IV94',
  'IV95',
  'IV96',
  'IV97',
  'IV98',
  'IV99',
  'KA27',
  'KA28',
  'KW0',
  'KW1',
  'KW2',
  'KW3',
  'KW4',
  'KW5',
  'KW6',
  'KW7',
  'KW8',
  'KW9',
  'KW10',
  'KW11',
  'KW12',
  'KW13',
  'KW14',
  'KW15',
  'KW16',
  'KW17',
  'PA20',
  'PA21',
  'PA22',
  'PA23',
  'PA24',
  'PA25',
  'PA26',
  'PA27',
  'PA28',
  'PA29',
  'PA30',
  'PA31',
  'PA32',
  'PA33',
  'PA34',
  'PA35',
  'PA36',
  'PA37',
  'PA38',
  'PA39',
  'PA40',
  'PA41',
  'PA42',
  'PA43',
  'PA44',
  'PA45',
  'PA46',
  'PA47',
  'PA48',
  'PA49',
  'PA50',
  'PA51',
  'PA52',
  'PA53',
  'PA54',
  'PA55',
  'PA56',
  'PA57',
  'PA58',
  'PA59',
  'PA60',
  'PA61',
  'PA62',
  'PA63',
  'PA64',
  'PA65',
  'PA66',
  'PA67',
  'PA68',
  'PA69',
  'PA70',
  'PA71',
  'PA72',
  'PA73',
  'PA74',
  'PA75',
  'PA76',
  'PA77',
  'PA78',
  'PH15',
  'PH16',
  'PH17',
  'PH18',
  'PH19',
  'PH20',
  'PH21',
  'PH22',
  'PH23',
  'PH24',
  'PH25',
  'PH26',
  'PH27',
  'PH28',
  'PH29',
  'PH30',
  'PH31',
  'PH32',
  'PH33',
  'PH34',
  'PH35',
  'PH36',
  'PH37',
  'PH38',
  'PH39',
  'PH40',
  'PH41',
  'PH42',
  'PH43',
  'PH44',
  'PH45',
  'PH46',
  'PH47',
  'PH48',
  'PH49',
  'PH50',
  'PH51',
  'PH52',
  'PH53',
  'PH54',
  'PH55',
  'PH56',
  'PH57',
  'PH58',
  'PH59',
  'PH60',
  'PH61',
  'PH62',
  'PH63',
  'PH64',
  'PH65',
  'PH66',
  'PH67',
  'PH68',
  'PH69',
  'PH70',
  'PH71',
  'PH72',
  'PH73',
  'PH74',
  'PH75',
  'PH76',
  'PH77',
  'PH78',
  'PH79',
  'PH80',
  'PH81',
  'PH82',
  'PH83',
  'PH84',
  'PH85',
  'PH86',
  'PH87',
  'PH88',
  'PH89',
  'PH90',
  'PH91',
  'PH92',
  'PH93',
  'PH94',
  'PH95',
  'PH96',
  'PH97',
  'PH98',
  'PH99',
  'TR21',
  'TR22',
  'TR23',
  'TR24',
  'TR25',
  'ZE1',
  'ZE2',
  'ZE3',
  'ZE4',
  'ZE5',
  'ZE6',
  'ZE7',
  'ZE8',
  'ZE9',
  'ZE10',
  'ZE11',
  'ZE12',
  'ZE13',
  'ZE14',
  'ZE15',
  'ZE16',
  'ZE17',
  'ZE18',
  'ZE19',
  'ZE20',
  'ZE21',
  'ZE22',
  'ZE23',
  'ZE24',
  'ZE25',
  'ZE26',
  'ZE27',
  'ZE28',
  'ZE29',
  'ZE30',
  'ZE31',
  'ZE32',
  'ZE33',
  'ZE34',
  'ZE35',
  'ZE36',
  'ZE37',
  'ZE38',
  'ZE39',
  'ZE40',
  'ZE41',
  'ZE42',
  'ZE43',
  'ZE44',
  'ZE45',
  'ZE46',
  'ZE47',
  'ZE48',
  'ZE49',
  'ZE50',
  'ZE51',
  'ZE52',
  'ZE53',
  'ZE54',
  'ZE55',
  'ZE56',
  'ZE57',
  'ZE58',
  'ZE59',
  'ZE60',
  'ZE61',
  'ZE62',
  'ZE63',
  'ZE64',
  'ZE65',
  'ZE66',
  'ZE67',
  'ZE68',
  'ZE69',
  'ZE70',
  'ZE71',
  'ZE72',
  'ZE73',
  'ZE74',
  'ZE75',
  'ZE76',
  'ZE77',
  'ZE78',
  'ZE79',
  'ZE80',
  'ZE81',
  'ZE82',
  'ZE83',
  'ZE84',
  'ZE85',
  'ZE86',
  'ZE87',
  'ZE88',
  'ZE89',
  'ZE90',
  'ZE91',
  'ZE92',
  'ZE93',
  'ZE94',
  'ZE95',
  'ZE96',
  'ZE97',
  'ZE98',
  'BT1',
  'BT2',
  'BT3',
  'BT4',
  'BT5',
  'BT6',
  'BT7',
  'BT8',
  'BT9',
  'BT10',
  'BT11',
  'BT12',
  'BT13',
  'BT14',
  'BT15',
  'BT16',
  'BT17',
  'BT18',
  'BT19',
  'BT20',
  'BT21',
  'BT22',
  'BT23',
  'BT24',
  'BT25',
  'BT26',
  'BT27',
  'BT28',
  'BT29',
  'BT30',
  'BT31',
  'BT32',
  'BT33',
  'BT34',
  'BT35',
  'BT36',
  'BT37',
  'BT38',
  'BT39',
  'BT40',
  'BT41',
  'BT42',
  'BT43',
  'BT44',
  'BT45',
  'BT46',
  'BT47',
  'BT48',
  'BT49',
  'BT50',
  'BT51',
  'BT52',
  'BT53',
  'BT54',
  'BT55',
  'BT56',
  'BT57',
  'BT58',
  'BT59',
  'BT60',
  'BT61',
  'BT62',
  'BT63',
  'BT64',
  'BT65',
  'BT66',
  'BT67',
  'BT68',
  'BT69',
  'BT70',
  'BT71',
  'BT72',
  'BT73',
  'BT74',
  'BT75',
  'BT76',
  'BT77',
  'BT78',
  'BT79',
  'BT80',
  'BT81',
  'BT82',
  'BT83',
  'BT84',
  'BT85',
  'BT86',
  'BT87',
  'BT88',
  'BT89',
  'BT90',
  'BT91',
  'BT92',
  'BT93',
  'BT94',
  'BT95',
  'BT96',
  'BT97',
  'BT98',
  'BT99',
  'JE1',
  'JE2',
  'JE3',
  'JE4',
  'JE5',
  'JE6',
  'JE7',
  'JE8',
  'JE9',
  'JE10',
  'JE11',
  'JE12',
  'JE13',
  'JE14',
  'JE15',
  'JE16',
  'JE17',
  'JE18',
  'JE19',
  'JE20',
  'JE21',
  'JE22',
  'JE23',
  'JE24',
  'JE25',
  'JE26',
  'JE27',
  'JE28',
  'JE29',
  'JE30',
  'JE31',
  'JE32',
  'JE33',
  'JE34',
  'JE35',
  'JE36',
  'JE37',
  'JE38',
  'JE39',
  'JE40',
  'JE41',
  'JE42',
  'JE43',
  'JE44',
  'JE45',
  'JE46',
  'JE47',
  'JE48',
  'JE49',
  'JE50',
  'JE51',
  'JE52',
  'JE53',
  'JE54',
  'JE55',
  'JE56',
  'JE57',
  'JE58',
  'JE59',
  'JE60',
  'JE61',
  'JE62',
  'JE63',
  'JE64',
  'JE65',
  'JE66',
  'JE67',
  'JE68',
  'JE69',
  'JE70',
  'JE71',
  'JE72',
  'JE73',
  'JE74',
  'JE75',
  'JE76',
  'JE77',
  'JE78',
  'JE79',
  'JE80',
  'JE81',
  'JE82',
  'JE83',
  'JE84',
  'JE85',
  'JE86',
  'JE87',
  'JE88',
  'JE89',
  'JE90',
  'JE91',
  'JE92',
  'JE93',
  'JE94',
  'JE95',
  'JE96',
  'JE97',
  'JE98',
  'JE99',
  'JE100',
  'GY1',
  'GY2',
  'GY3',
  'GY4',
  'GY5',
  'GY6',
  'GY7',
  'GY8',
  'GY9',
  'GY10',
  'GY11',
  'GY12',
  'GY13',
  'GY14',
  'GY15',
  'GY16',
  'GY17',
  'GY18',
  'GY19',
  'GY20',
  'GY21',
  'GY22',
  'GY23',
  'GY24',
  'GY25',
  'GY26',
  'GY27',
  'GY28',
  'GY29',
  'GY30',
  'GY31',
  'GY32',
  'GY33',
  'GY34',
  'GY35',
  'GY36',
  'GY37',
  'GY38',
  'GY39',
  'GY40',
  'GY41',
  'GY42',
  'GY43',
  'GY44',
  'GY45',
  'GY46',
  'GY47',
  'GY48',
  'GY49',
  'GY50',
  'GY51',
  'GY52',
  'GY53',
  'GY54',
  'GY55',
  'GY56',
  'GY57',
  'GY58',
  'GY59',
  'GY60',
  'GY61',
  'GY62',
  'GY63',
  'GY64',
  'GY65',
  'GY66',
  'GY67',
  'GY68',
  'GY69',
  'GY70',
  'GY71',
  'GY72',
  'GY73',
  'GY74',
  'GY75',
  'GY76',
  'GY77',
  'GY78',
  'GY79',
  'GY80',
  'GY81',
  'GY82',
  'GY83',
  'GY84',
  'GY85',
  'GY86',
  'GY87',
  'GY88',
  'GY89',
  'GY90',
  'GY91',
  'GY92',
  'GY93',
  'GY94',
  'GY95',
  'GY96',
  'GY97',
  'GY98',
  'GY99'
])

import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Text } from '@tofu/shared/ui/atoms/text'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@tofu/shared/ui/atoms/modal'

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalPaymentError = NiceModal.create(
  ({ error }: { error: string }) => {
    const modal = useModal()

    return (
      <Modal
        size='md'
        isCentered
        isOpen={modal.visible}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Oops, we couldn't complete your payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text dangerouslySetInnerHTML={{ __html: error }}></Text>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  }
)

import {
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
  extendTheme
} from '@chakra-ui/react'

import { inputAnatomy } from '@chakra-ui/anatomy'

/**
 * Styling chakra ui match Stripe's styling
 */

const focus = defineStyle({
  _focus: {
    outline: '0px',
    borderColor: '#0570de',
    boxShadow:
      '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)'
  }
})

const boxShadow =
  '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    color: 'rgb(48, 49, 61)',
    fontFamily:
      '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '16px',
    _hover: {
      outline: '0px'
    },
    _focusVisible: {
      boxShadow,
      outline: '0px',
      borderColor: '#0570de'
    },
    _focusWithin: {
      boxShadow,
      outline: '0px',
      borderColor: '#0570de'
    }
  }
})

export const inputTheme = defineMultiStyleConfig({ baseStyle })

export const textareaTheme = defineStyleConfig({
  variants: { outline: focus }
})

export const themeCheckout = extendTheme({
  components: { Textarea: textareaTheme, Input: inputTheme },
  semanticTokens: {
    colors: {
      'chakra-placeholder-color': '#757680'
    }
  }
})

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'

import { TLayoutCheckoutContentAside } from './layout-checkout-content-aside.types'

export const LayoutCheckoutContentAside: TLayoutCheckoutContentAside = ({
  children,
  ...propsFlex
}) => (
  <Flex
    order={{ base: 0, lg: 1 }}
    width={{ base: '100%', lg: '45%' }}
    bg='#f5f5f5'
    minHeight={{ lg: '100vh' }}
    borderBottom={{ base: '1px solid #D6D6D6', lg: 'none' }}
    {...propsFlex}
  >
    <Flex
      width='100%'
      position='sticky'
      top={0}
      alignSelf='flex-start'
      overflowY='auto'
    >
      <Box maxW={{ base: '100%', lg: 460 }} width='100%'>
        {children}
      </Box>
    </Flex>
  </Flex>
)

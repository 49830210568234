import { useEffect, useState, createContext, PropsWithChildren } from 'react'
import type { RudderAnalytics } from '@rudderstack/analytics-js'

import { environment } from '@tofu/shared/constants/environments'

import { splitAndCompact } from './provider-rudderstack-checkout.utils'

export const ProviderRudderstackCheckoutContext = createContext<{
  isInitialized: boolean
  rudderstackAnalytics: RudderAnalytics | undefined
}>({
  rudderstackAnalytics: undefined,
  isInitialized: false
})

export const ProviderRudderstackCheckoutProvider = ({
  children
}: PropsWithChildren) => {
  const [rudderstackAnalytics, setRudderstackAnalytics] =
    useState<RudderAnalytics>()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    let mounted = true

    const initialize = async () => {
      try {
        const { RudderAnalytics } = await import('@rudderstack/analytics-js')
        const analyticsInstance = new RudderAnalytics()
        const allowedConsentIds =
          typeof window !== 'undefined' &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          splitAndCompact(window?.OnetrustActiveGroups)

        await analyticsInstance.load(
          environment.rudderstack_write_key as string,
          environment.rudderstack_dataplane_url as string,
          {
            consentManagement: {
              enabled: true,
              allowedConsentIds: allowedConsentIds as string[],
              provider: 'custom'
            }
          }
        )

        return new Promise<RudderAnalytics>((resolve) => {
          analyticsInstance.ready(() => {
            if (mounted) {
              setRudderstackAnalytics(analyticsInstance)
              setIsInitialized(true)
              resolve(analyticsInstance)
            }
          })
        })
      } catch (error) {
        console.error('Failed to initialize Rudderstack:', error)
        // eslint-disable-next-line unicorn/no-useless-undefined
        return undefined // Add this line
      }
    }

    if (!rudderstackAnalytics) {
      initialize()
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProviderRudderstackCheckoutContext.Provider
      value={{ rudderstackAnalytics, isInitialized }}
    >
      {children}
    </ProviderRudderstackCheckoutContext.Provider>
  )
}

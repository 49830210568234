import find from 'lodash/find'
import { removeDeliveryItemFromCartItems } from '@tofu/shared/utils/cart'

import {
  TCartUtils,
  TGetTotalSavings,
  TConvertDeliveryFrequencyToWeeks
} from './use-cart-utils-checkout.types'

const convertDeliveryFrequencyToWeeks: TConvertDeliveryFrequencyToWeeks = (
  frequency
) => {
  switch (frequency) {
    case 'ONE_WEEK':
      return 1
    case 'TWO_WEEKS':
      return 2
    case 'THREE_WEEKS':
      return 3
    case 'FOUR_WEEKS':
      return 4
    case 'SIX_WEEKS':
      return 6
    default:
      return null
  }
}

const getTotalSavings: TGetTotalSavings = (
  hasDeliveryItem,
  totalDiscount,
  deliveryCharge
) => {
  // is free delivery
  if (!hasDeliveryItem) {
    return Number(
      ((totalDiscount ?? 0) / 100 + (deliveryCharge ?? 0)).toFixed(4)
    )
  }

  return Number(((totalDiscount ?? 0) / 100).toFixed(4))
}

// TODO: come back to this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
export const cartUtils: TCartUtils = (cart, deliveryCharge) => {
  if (!deliveryCharge) return null

  const cartDeliveryItem = find(cart?.items, {
    product_type: 'DELIVERY'
  })

  const cartPriceTotal = (cart?.total_price_to_charge ?? 0) / 100
  const cartPriceSubtotal = (cart?.subtotal_price ?? 0) / 100
  const isCartSubscription = cart?.subscription_type === 'RECURRING'
  const cartDeliveryFrequency = convertDeliveryFrequencyToWeeks(cart?.frequency)
  const cartDiscountCode = cart?.discount_codes?.[0]?.code
  const cartDiscountTotal = (cart?.total_discount ?? 0) / 100
  const cartDeliveryItemPrice = (cartDeliveryItem?.price ?? 0) / 100
  const cartItemsWithoutDeliveryCharge = cart?.items
    ? removeDeliveryItemFromCartItems(cart.items)
    : []

  const totalSavings = getTotalSavings(
    !!cartDeliveryItem,
    cart?.total_discount,
    deliveryCharge
  )

  return {
    cartDiscountTotal,
    cartDeliveryItemPrice,
    cartDiscountCode,
    totalSavings,
    cartPriceTotal,
    cartPriceSubtotal,
    isCartSubscription,
    cartDeliveryFrequency,
    cartItemsWithoutDeliveryCharge
  }
}

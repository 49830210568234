// import { useEffect } from 'react'
// import Script from 'next/script'
// import { environment } from '@tofu/shared/constants/environments'

// const TrackingScriptsCheckoutOneTrust = () => (
//   <>
//     <Script
//       type='text/javascript'
//       strategy='beforeInteractive'
//       data-domain-script={environment.one_trust_id}
//       src='https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
//     />

//     <Script
//       dangerouslySetInnerHTML={{
//         __html: `
//           function OptanonWrapper() {
//             if (window.OneTrust.IsAlertBoxClosed()) {
//               // Load the Rudderstack SDK
//               rudderanalytics.load('${environment.rudderstack_write_key}', '${environment.rudderstack_dataplane_url}', {
//                 cookieConsentManager: {
//                   oneTrust: {
//                     enabled: true
//                   }
//                 }
//               });
//             }
//           }
//           `
//       }}
//       id='OneTrust Callback'
//       type='text/javascript'
//     />
//   </>
// )

// const TrackingScriptsCheckoutOneTrust = () => {
//   useEffect(() => {
//     if (typeof window === 'undefined')
//       return // Define the OptanonWrapper function provided by OneTrust
//     ;(window as any).OptanonWrapper = function () {
//       const analyticsConsent = (window as any).OnetrustActiveGroups?.includes(
//         'C0002'
//       ) // Replace 'C0002' with the correct category ID for Analytics

//       if (analyticsConsent) {
//         console.log('analyticsConsent', analyticsConsent)

//         // Load RudderStack
//         ;(function () {
//           const rudderScript = document.createElement('script')
//           rudderScript.innerHTML = `!function(){"use strict";window.RudderSnippetVersion="3.0.32";var e="rudderanalytics";window[e]||(window[e]=[])
//   ;var rudderanalytics=window[e];if(Array.isArray(rudderanalytics)){
//   if(true===rudderanalytics.snippetExecuted&&window.console&&console.error){
//   console.error("RudderStack JavaScript SDK snippet included more than once.")}else{rudderanalytics.snippetExecuted=true,
//   window.rudderAnalyticsBuildType="legacy";var sdkBaseUrl="https://cdn.rudderlabs.com/v3";var sdkName="rsa.min.js"
//   ;var scriptLoadingMode="async"
//   ;var r=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession","consent"]
//   ;for(var n=0;n<r.length;n++){var t=r[n];rudderanalytics[t]=function(r){return function(){var n
//   ;Array.isArray(window[e])?rudderanalytics.push([r].concat(Array.prototype.slice.call(arguments))):null===(n=window[e][r])||void 0===n||n.apply(window[e],arguments)
//   }}(t)}try{
//   new Function('class Test{field=()=>{};test({prop=[]}={}){return prop?(prop?.property??[...prop]):import("");}}'),
//   window.rudderAnalyticsBuildType="modern"}catch(o){}var d=document.head||document.getElementsByTagName("head")[0]
//   ;var i=document.body||document.getElementsByTagName("body")[0];window.rudderAnalyticsAddScript=function(e,r,n){
//   var t=document.createElement("script");t.src=e,t.setAttribute("data-loader","RS_JS_SDK"),r&&n&&t.setAttribute(r,n),
//   "async"===scriptLoadingMode?t.async=true:"defer"===scriptLoadingMode&&(t.defer=true),
//   d?d.insertBefore(t,d.firstChild):i.insertBefore(t,i.firstChild)},window.rudderAnalyticsMount=function(){!function(){
//   if("undefined"==typeof globalThis){var e;var r=function getGlobal(){
//   return"undefined"!=typeof self?self:"undefined"!=typeof window?window:null}();r&&Object.defineProperty(r,"globalThis",{
//   value:r,configurable:true})}
//   }(),window.rudderAnalyticsAddScript("".concat(sdkBaseUrl,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkName),"data-rsa-write-key","23xklIwRG7gvGXzkBwBTMqp0nzq")
//   },
//   "undefined"==typeof Promise||"undefined"==typeof globalThis?window.rudderAnalyticsAddScript("https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount"):window.rudderAnalyticsMount()
//   ;var loadOptions={};rudderanalytics.load("23xklIwRG7gvGXzkBwBTMqp0nzq","https://allplantsubu.dataplane.rudderstack.com",loadOptions)}}}();`

//           document.head.append(rudderScript)
//         })()
//       }
//     }
//   }, [])

//   return (
//     <>
//       {/* <Script
//         id='one-trust-sdk'
//         strategy='beforeInteractive'
//         src='https://cdn-ukwest.onetrust.com/consent/cf179337-25b2-4b25-a196-48236fa54097/otSDKStub.js'
//         type='text/javascript'
//         data-domain-script='cf179337-25b2-4b25-a196-48236fa54097-test'
//       /> */}
//       <Script
//         data-domain-script={environment.one_trust_id}
//         id='one-trust-sdk'
//         strategy='beforeInteractive'
//         src='https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
//         type='text/javascript'
//       />
//       {/* <Script type='text/javascript'>function OptanonWrapper() {}</Script> */}
//     </>
//   )
// }

// const TrackingScriptsCheckoutGTM = () => (
//   <>
//     <script>{`
//       window.dataLayer=window.dataLayer||[];
//     `}</script>

//     <Script id='gtm-script' strategy='afterInteractive' defer>
//       {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','dataLayer','${environment.gtm_id}');`}
//     </Script>

//     <script
//       // eslint-disable-next-line react/no-danger
//       dangerouslySetInnerHTML={{
//         __html: `window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('consent', 'default', {
//         'ad_storage': 'denied',
//         'analytics_storage': 'denied',
//         'functionality_storage': 'denied',
//         'personalization_storage': 'denied',
//         'security_storage': 'denied',
//       });`
//       }}
//       defer
//       type='text/javascript'
//       id='consent-default'
//     />
//   </>
// )

export const TrackingScriptsCheckout = () => {
  return (
    <>
      {/* <TrackingScriptsCheckoutOneTrust /> */}
      {/* <TrackingScriptsCheckoutGTM /> */}
    </>
  )
}

import { Link } from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton
  // ModalFooter
} from '@tofu/shared/ui/atoms/modal'
import { Text } from '@tofu/shared/ui/atoms/text'
// import { Button } from '@tofu/shared/ui/atoms/button'
import { VStack } from '@tofu/shared/ui/atoms/stack'

// eslint-disable-next-line import/no-named-as-default-member
export const TemplateCheckoutModalDeliveryAddressError = NiceModal.create(
  () => {
    const modal = useModal()

    return (
      <Modal
        size='lg'
        isCentered
        isOpen={modal.visible}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sorry, we couldn't complete your payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align='flex-start'>
              <Text>
                We're sorry, but it looks like we don't deliver to your post
                code just yet.
              </Text>
              <Text>
                If you'd like us to let you know when we expand to your
                postcode, please get in touch with us - we'd love to keep you
                updated!
              </Text>
              <Text>
                You can contact us on our{' '}
                <Link
                  textDecoration='underline'
                  href='https://allplants.com/contact'
                >
                  support page <ExternalLinkIcon />
                </Link>
              </Text>
              <Text>
                If you'd like us to delivery to a different address, please
                update your postcode and try again.
              </Text>
            </VStack>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => modal.remove()}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    )
  }
)

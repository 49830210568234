import { z } from 'zod'

export const DeliveryDays = z.object({
  min: z.string().nullable(),
  max: z.string().nullable(),
  first_date: z.string().nullable(),
  excluded_dates: z.array(z.string()).nullable()
})
export type TDeliveryDays = z.infer<typeof DeliveryDays>

export const DeliveryDaysResponse = z.object({
  data: z.array(DeliveryDays),
  status: z.number()
})
export type TDeliveryDaysResponse = z.infer<typeof DeliveryDaysResponse>
